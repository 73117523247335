import React, { useMemo } from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { colors } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Page from '~/layout/LayoutMarketing';
import Section from '~/components/Section';
import UserstandLogo from '~/components/blog/UserstandLogo';
import FeaturedPost from '~/components/blog/FeaturedPost';
import ComingSoon from '~/components/blog/ComingSoon';
import SubscribeSection from '~/components/blog/SubscribeSection';

const Styled = styled.div`
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .blog--opener {
    margin-top: -4rem;
    padding-top: 8rem;
    padding-bottom: 5rem;
    background: ${colors.PURPLE_DARK(0.05)};
  }
  .blog__opener {
    align-items: center;
  }
  .blog__opener__title {
    flex: 0 0 30%;
  }
  .blog__opener__featured {
    flex: 0 0 65%;
  }

  .blog__secondary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }

  .blog__secondary .post {
    width: 100%;
  }

  .blog--coming-soon {
    padding-top: 0;
    text-align: center;
  }
  .blog__coming-soon__posts {
    margin: auto;
    margin-top: 2rem;
    max-width: 50rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
  }

  @media screen and (max-width: 800px) {
    .container {
      flex-flow: column;
    }
    .blog__opener__featured {
      margin-top: 2rem;
    }
  }
  @media screen and (max-width: 500px) {
    .blog__secondary {
      grid-template-columns: 1fr;
    }
    .blog__coming-soon__posts {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media screen and (max-width: 400px) {
    .blog__coming-soon__posts {
      grid-template-columns: 1fr;
    }
  }
`;

interface IQuery {
  allContentfulInterview: {
    nodes: IInterview[];
  };
  allContentfulComingSoon: {
    nodes: IComingSoon[];
  };
}

const Blog: React.FC<{ data: IQuery }> = (props) => {
  const {
    allContentfulInterview: { nodes: interviews },
    allContentfulComingSoon: { nodes: soon },
  } = props.data;

  const [featured, posts] = useMemo(() => {
    const newPosts = [...interviews];
    const featuredIndex = newPosts.findIndex((interview) => interview.featured);
    const featuredPost = newPosts.splice(featuredIndex, 1)[0];
    return [featuredPost, newPosts];
  }, [interviews]);

  return (
    <Page
      title="Userstand | How customer-obsessed companies understand their users"
      image={`https:${featured.user.photo.file.url}`}
      description="A blog by Herald. Real stories about how the most customer-obsessed companies in the world listen and take action on customer feedback."
    >
      <Styled>
        <Section className="blog--opener">
          <Container className="container blog__opener">
            <div className="blog__opener__title">
              <UserstandLogo />
            </div>
            <div className="blog__opener__featured">
              <Link to={`/userstand/${featured.slug}`}>
                <FeaturedPost interview={featured} />
              </Link>
            </div>
          </Container>
        </Section>
        {posts.length && posts.length > 0 && (
          <Section className="blog--secondary">
            <Container className="blog__secondary">
              {posts.map((post) => (
                <Link key={post.slug} to={`/userstand/${post.slug}`}>
                  <FeaturedPost interview={post} secondary={true} />
                </Link>
              ))}
            </Container>
          </Section>
        )}
        {/*<Section className="blog--coming-soon">
          <Container className="blog__coming-soon">
            <label>Coming Soon</label>
            <div className="blog__coming-soon__posts">
              {soon.map((comingSoon) => (
                <ComingSoon key={comingSoon.id} post={comingSoon} />
              ))}
            </div>
          </Container>
              </Section>*/}
        <SubscribeSection />
      </Styled>
    </Page>
  );
};

export const query = graphql`
  query {
    allContentfulComingSoon(limit: 3, sort: { fields: postedAt, order: ASC }) {
      nodes {
        id
        title
        subtitle
        postedAt(formatString: "MMMM D, YYYY")
        image {
          fluid(maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    allContentfulInterview(sort: { fields: postedAt, order: DESC }) {
      nodes {
        title
        createdAt(formatString: "MMMM D, YYYY")
        postedAt(formatString: "MMMM D, YYYY")
        slug
        featured
        author {
          name
        }
        summary {
          summary
        }
        body {
          json
        }
        user {
          name
          title
          photo {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
          company {
            name
            website
            summary {
              summary
            }
            logo {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_withWebp
              }
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default Blog;
